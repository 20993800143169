import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBvGMB5QFOfj6NjPDQSBZ9fu8XYokIpSA4",
  authDomain: "techrepair-sw-canada.firebaseapp.com",
  projectId: "techrepair-sw-canada",
  storageBucket: "techrepair-sw-canada.appspot.com",
  messagingSenderId: "120361292390",
  appId: "1:120361292390:web:8b260223e84992374853a7",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = db.doc(`techs/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email } = user;
    try {
      await userRef.set({
        email,
        ...additionalData,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
