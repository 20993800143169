import React, { useContext, useState } from "react";
import { auth } from "../../firebase";
import { AuthContext } from "../../Providers/UserProvider";
import logo from "../../Assets/logo.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { error: authError } = useContext(AuthContext);

  const signInWithEmailAndPasswordHandler = (event, mail, pass) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(mail, pass).catch((errors) => {
      setError(errors.message.split(".")[0]);
    });
  };

  const onChangeHandler = (setFunc) => (evt) =>
    setFunc(evt.currentTarget.value);

  return (
    <div className="bg-cover min-h-screen bg-gradient-to-r from-black to-blue-600">
      <div className="flex flex-col">
        <div className="mt-32 text-center mx-auto">
          <img src={logo} alt="Logo" className="text-center mx-auto w-8/12" />
        </div>
        <div className="text-center mt-10">
          {error !== null && (
            <div className="py-4 bg-blue-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          {authError && (
            <div className="py-4 bg-blue-600 w-full text-white text-center mb-3">
              {authError}
            </div>
          )}
          <form
            className="flex-col"
            onSubmit={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
          >
            <div className="my-4">
              <input
                type="email"
                name="userEmail"
                value={email}
                onChange={onChangeHandler(setEmail)}
                placeholder="Email"
                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
              />
            </div>
            <div className="my-4">
              <input
                type="password"
                name="userPassword"
                value={password}
                onChange={onChangeHandler(setPassword)}
                placeholder="Password"
                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
              />
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 rounded w-72"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
