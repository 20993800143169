import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { auth, functions } from "../../firebase";
import { Formik } from "formik";
import * as Yup from "yup";
import logo from "../../Assets/logo.png";

const SignupPage = ({ match, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { regionId, shopId } = match.params;
  const [validatingRealTime, setValidatingRealTime] = useState(false);

  const signupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    password: Yup.string()
      .min(5, "Password length must be longer than 6 characters")
      .required("This field is required"),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      })
      .required("This field is required"),
  });

  useEffect(() => {
    if (!shopId || !regionId) {
      return history.replace("/login");
    }
    (async () => {
      setIsLoading(true);
      const {
        data: { exists },
      } = await functions.httpsCallable("checkShopExistence")({
        shopId,
        regionId,
      });
      setIsLoading(false);
      if (!exists) {
        return history.replace("/login");
      }
    })();
  }, [regionId, shopId, history]);

  const signInWithEmailAndPasswordHandler = useCallback(
    (event) => {
      setIsLoading(true);
      functions
        .httpsCallable("registerShopUser")({
          regionId,
          shopId,
          email,
          password,
        })
        .then(() => auth.signInWithEmailAndPassword(email, password))
        .then(() => {
          history.push("/");
          setIsLoading(false);
        })
        .catch((err) => {
          alert(err.message);
          setIsLoading(false);
        });
    },
    [regionId, shopId, email, password, history]
  );

  return (
    <div className="bg-cover min-h-screen bg-gradient-to-l from-black to-blue-600">
      <div className="flex flex-col">
        <div className="mt-32 text-center mx-auto">
          <img src={logo} alt="Logo" className="text-center mx-auto w-8/12" />
        </div>
        {isLoading && (
          <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
              <i className="fas fa-circle-notch fa-spin fa-5x"></i>
            </span>
          </div>
        )}
        <div className="text-center mt-10">
          <Formik
            initialValues={{
              email: "",
              password: "",
              changepassword: "",
            }}
            validationSchema={signupSchema}
            onSubmit={signInWithEmailAndPasswordHandler}
            validateOnChange={validatingRealTime}
            validateOnBlur={false}
          >
            {(props) => {
              return (
                <form
                  className="flex-col"
                  onSubmit={signInWithEmailAndPasswordHandler}
                >
                  <div className="my-4">
                    <input
                      type="email"
                      name="email"
                      value={props.email}
                      onBlur={props.handleBlur}
                      onChange={(text) => {
                        props.handleChange("email")(text.target.value);
                        setEmail(text.target.value);
                      }}
                      placeholder="Email"
                      className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                    />
                  </div>
                  {props.errors.email && (
                    <p className="text-red-500 text-xs italic mt-2">
                      {props.errors.email}
                    </p>
                  )}
                  <div className="my-4">
                    <input
                      type="password"
                      name="password"
                      value={props.password}
                      onBlur={props.handleBlur}
                      onChange={(text) => {
                        props.handleChange("password")(text.target.value);
                        setPassword(text.target.value);
                      }}
                      placeholder="Password"
                      className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                    />
                    {props.errors.password && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {props.errors.password}
                      </p>
                    )}
                  </div>
                  <div className="my-4">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={props.confirmPassword}
                      placeholder="Confirm Password"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                    />
                    {props.errors.confirmPassword && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {props.errors.confirmPassword}
                      </p>
                    )}
                  </div>
                  <div className="mt-8">
                    <button
                      type="button"
                      className="bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 rounded w-72"
                      onClick={() => {
                        props.handleSubmit();
                        setValidatingRealTime(true);
                      }}
                    >
                      Signup
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="mt-8">
            <NavLink
              to="/login"
              className="py-3 pl-1 align-middle text-gray-100"
            >
              <span className="pb-0 text-base">
                Already have an account? Login
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignupPage;
